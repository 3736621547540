import { getConfig } from "@engaging-tech/ssr-config"
import React, { createElement } from "react"

import QuestionListContainer from "../../containers/Page2/QuestionList"
import SectionBreak from "./SectionBreak"

const SectionSteps = () => {
  const config = getConfig()

  const sectionData = [
    {
      title: "Survey Questions",
      text: `Below are a list of questions especially designed to measure engagement, wellbeing and employee experience. If you wish to amend any of the 22 questions included in the Self Serve package, please email our Client Success team on <u><a href='mailto:support@workl.com'>support@workl.com</a></u> or call 020 4599 8333 to discuss our <u><a href='${config.publicSite}/pricing-plans?service=engagement-surveys' target="_blank" rel="noopener noreferrer">Bespoke Survey options</a></u>.`,
      child: QuestionListContainer,
      mb: 4
    }
    // {
    //   title: "Additional Questions",
    //   text:
    //     "You can add up to 4 custom questions to your survey using the boxes below. If you would like to add more questions, please email our Client Success team on <u>support@workl.com</u> or call 020 4599 8333 to discuss our <u>Bespoke Survey options</u>.",
    //   pb: 4
    // }
  ]

  return (
    <>
      {sectionData.map(({ child, text, title, mb }, i) => (
        <SectionBreak
          text={text}
          title={title}
          key={title}
          length={sectionData.length}
          currentPage={i + 1}
          mb={mb}
        >
          {child && createElement(child)}
        </SectionBreak>
      ))}
    </>
  )
}

export default SectionSteps
