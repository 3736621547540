/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from "@engaging-tech/components"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import DashboardContent from "../../features/selfServiceSurveys/components/DashboardContent"
import FlexibleSelfServeSurveys from "../../features/selfServiceSurveys/components/FlexibleSelfServeSurveys"
import LandingBanner from "../../features/selfServiceSurveys/components/LandingBanner"
import StartSurveyButtons from "../../features/selfServiceSurveys/components/StartSurveyButtons"
import SurveyFlow from "../../features/selfServiceSurveys/components/SurveyFlow"
import SurveyQuestions from "../../features/selfServiceSurveys/components/SurveyQuestions"
import { resetPage } from "../../features/selfServiceSurveys/store/actions"
import ContentBlock from "../../features/ui/components/ContentBlock"
import PageLayout from "../../layouts/PageLayout"

const LandingPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetPage())
  }, [])

  return (
    <PageLayout banner={LandingBanner}>
      <Flex
        flexDirection="column"
        py={6}
        px={1}
        alignItems="center"
        width={1 / 1}
      >
        <Text
          color="secondary.0"
          fontWeight="700"
          fontSize={7}
          textAlign="center"
        >
          Self Serve setup
        </Text>
        <SurveyFlow />
      </Flex>
      <FlexibleSelfServeSurveys />
      <SurveyQuestions />
      <ContentBlock
        bg="secondary.0"
        flexDirection="column"
        description="The process will only take no more than 10 minutes and you will need your company card or relevant details to generate an invoice."
        descriptionStyles={{ color: "light.0", textAlign: "center" }}
      >
        <Flex flexDirection="column" alignItems="center">
          <StartSurveyButtons />
          <Text color="light.0" textAlign="center" mt={2}>
            If you have any questions about the dashboards or reporting please
            contact{" "}
            <u>
              <a href="mailto:support@workl.com">support@workl.com.</a>
            </u>
          </Text>
        </Flex>
      </ContentBlock>
      <DashboardContent
        title="Survey Dashboard and Reporting"
        imgSrc="https://d19vbgnwz7jfjm.cloudfront.net/Mac_Studio.svg"
        imgFooterText="If you have any questions about the dashboards, reporting or if you'd like support with upgrading your account please contact"
        paragraph1="When your survey closes you'll get access to your own interactive dashboard to explore the data and deep dive into how specific groups feel. You'll receive an engagement score, your scores in the six key areas, a word cloud, NPS score and much more!"
        paragraph2="You can easily filter all of your results by different demographic groups. If you'd like enhanced data and reporting including predictive analytics on D&I, Wellbeing and Flight Risk and action plans and resources delivered through our IAS™ software then you can upgrade your account to include slice and dice."
        footerLink="Click here for a preview"
      />
    </PageLayout>
  )
}

export default LandingPage
