import { Card, Flex, Paragraph, Text } from "@engaging-tech/components"
import { Formik } from "formik"
import React from "react"
import { object, string } from "yup"

import InvoiceForm from "./InvoiceForm"

const validation = object().shape({
  firstName: string("Please enter your first name").required(
    "Please enter your first name"
  ),
  lastName: string("Please enter your last name").required(
    "Please enter your last name"
  ),
  email: string("Please enter your email address")
    .email("Please enter a valid email address")
    .required("An email address is required"),
  addressFirstLine: string("Please enter your address").required(
    "An address is required"
  ),

  city: string("Please enter your town or city").required(
    "A valid town or city is required"
  ),
  companyName: string("Please enter your company name").required(
    "Please enter your company name"
  ),
  country: string("Please enter your country").required(
    "A valid country name is required"
  )
})

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  addressFirstLine: "",
  addressSecondLine: "",
  postcode: "",
  city: "",
  country: "",
  companyName: ""
}

const InvoiceBody = ({ submitAction, onBack }) => {
  const handleSubmit = e => submitAction({ ...e })

  return (
    <Flex flexDirection="column">
      <Card p={4}>
        <Flex mb={3}>
          <Text fontSize={[4, 5]} fontWeight="600" color="secondary.0">
            Billing information
          </Text>
          <Text fontSize={[6, 7]} fontWeight="600" color="secondary.0">
            Invoice
          </Text>
        </Flex>
        <Paragraph mb={5} color="secondary.0">
          By clicking complete purchase you are agreeing to the following
          payment terms:
        </Paragraph>
        <Paragraph ml={3} color="secondary.0">
          - You have a 7 day period to pay from the date of the invoice being
          generated
        </Paragraph>
        <Paragraph ml={3} color="secondary.0">
          - Payment can be made via various methods including (but not limited
          to) bank transfer and credit/debit card.
        </Paragraph>
        <Paragraph ml={3} color="secondary.0">
          - If payment is not received within 30 days then your survey will be
          disabled
        </Paragraph>
        <Paragraph ml={3} color="secondary.0">
          - If payment is not received in advance of the awards closing then
          your organisation will be automatically disqualified
        </Paragraph>
        <Paragraph ml={3} color="secondary.0">
          - If you have any questions please contact{" "}
          <a href="mailto:support@workl.com">
            <u>support@workl.com</u>
          </a>{" "}
          or if your question is specific to Awards please contact us at{" "}
          <a href="mailto:workplaceawards@workl.com">
            <u>workplaceawards@workl.com</u>
          </a>
          .
        </Paragraph>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          {props => <InvoiceForm {...props} onBack={onBack} />}
        </Formik>
      </Card>
    </Flex>
  )
}

export default InvoiceBody
