const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_seV9XKIQM',
  aws_user_pools_web_client_id: '1gh60b36qti0u1r2urg1dfmd1d',
  oauth: {},
  aws_appsync_graphqlEndpoint: 'https://pe26s6wswvgd7fm4eqwtf6a5y4.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};

export default awsmobile;
